<template>
  <section id="home" class="hero-section min-vh-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-7">
          <div class="hero-content">
            <h1 class="wow fadeInUp" data-wow-delay=".2s">MENTIONS LÉGALES</h1>
          </div>
        </div>
        <div class="col-lg-12">
          <div style="color: black;">
            <div data-v-27a8ad59="" class="container-xl"><h1 data-v-27a8ad59="" class="font-weight-bold mb-5">
              Mentions légales
            </h1><h2 data-v-27a8ad59="" class="mt-5 mb-3">Editeur</h2><p data-v-27a8ad59="">
              S.A.S WHAT A FIX<br data-v-27a8ad59="">
              Capital 200 002 euros<br data-v-27a8ad59="">
              819466673 RCS PARIS<br data-v-27a8ad59="">
              Numéro de TVA Intracommunautaire : FR21819466673<br data-v-27a8ad59="">
              21 Bis Rue du Simplon<br data-v-27a8ad59="">
              75018 PARIS
            </p><h2 data-v-27a8ad59="" class="mt-5 mb-3">Responsable publication</h2><p data-v-27a8ad59="">
              F. Khiat<br data-v-27a8ad59="">
              Tel : +33 (0)7 83 26 11 74<br data-v-27a8ad59="">
              Email : contact@whatafix.com
            </p><h2 data-v-27a8ad59="" class="mt-5 mb-3">Hebergeur du site</h2><p data-v-27a8ad59="">
              OVH<br data-v-27a8ad59="">
              SAS au capital de 10 069 020 €<br data-v-27a8ad59="">
              RCS Lille Métropole 424 761 419 00045<br data-v-27a8ad59="">
              Code APE 2620Z<br data-v-27a8ad59="">
              N° TVA : FR 22 424 761 419<br data-v-27a8ad59="">
              Siège social : 2 rue Kellermann - 59100 Roubaix - France
            </p><h2 data-v-27a8ad59="" class="mt-5 mb-3">
              Déclaration CNIL et loi Informatique et Libertés
            </h2><p data-v-27a8ad59="">
              En application de la loi n°78-17 du 6 janvier 1978 modifiée
              Informatique et Libertés, il est a rappelé que les données
              nominatives des Utilisateurs collectées par WHAT A FIX, ont
              fait l’objet d’une déclaration auprès de la CNIL (Commission
              Nationale de l’Informatique et des Libertés (www.cnil.fr),
              déclaration n°1948902 v 0. L’Utilisateur bénéficie d’un
              droit d’accès, d’interrogation, de modification, de
              suppression des données le concernant ce qui lui permet, le
              cas échéant, de faire modifier, compléter, supprimer les
              données personnelles le concernant qui sont inexactes,
              incomplètes, équivoques, périmées ou dont la collecte,
              l’utilisation, la communication, le partage ou la détention
              est interdite. Ce droit est applicable en nous contactant à
              l’adresse suivante : S.A.S WHAT A FIX , 21 Bis Rue du
              Simplon 75018 PARIS, ou par mail à l’adresse suivante:
              contact@whatafix.com. Toute demande doit être écrite et
              signée par l’Utilisateur, accompagnée d’une photocopie d’une
              pièce d’identité en cours de validité. Les informations
              ainsi collectées sont destinées à être utilisées
              exclusivement par S.A.S WHAT A FIX et ses prestataires.
              Toute diffusion à des tiers des données personnelles
              recueillies sur les Utilisateurs fera l’objet d’une demande
              expresse et préalable. L’Utilisateur est informé que
              l’utilisation de ses données personnelles qu’il pourrait
              communiquer est réservée à S.A.S WHAT A FIX, mais qu’elles
              peuvent faire l’objet d’un partage avec un ou plusieurs de
              ses partenaires ou sous-traitants dans la mesure nécessaire
              à la mise en place et à l’accomplissement du service proposé
              ou à des finalités commerciales ou publicitaires et cela, à
              la seule initiative de S.A.S WHAT A FIX. En outre, certaines
              données personnelles permettant une identification indirecte
              peuvent également être collectées et traitées afin de mieux
              gérer la connexion et la navigation des Utilisateurs sur le
              Site. Par conséquent S.A.S WHAT A FIX est susceptible
              d’utiliser des cookies.
            </p><h2 data-v-27a8ad59="" class="mt-5 mb-3">Cookies</h2><p data-v-27a8ad59="">
              S.A.S WHAT A FIX fait fait appel a des services externes
              susceptibles d’utiliser des cookies afin de fournir des
              informations quantitatives et qualitatives quant aux
              Utilisateurs du site web. Les principaux programmes
              utilisant les cookies sont:Facebook, Linkedin, Google &amp;
              Google Analytics ainsi que Twitter. Veuillez vous référer
              aux Conditions Générales d’Utilisation, pour obtenir plus
              d’information sur le mode de fonctionnement des cookies et
              leur utilisation. Droit d’auteurs, propriété intellectuelle
              Tous les éléments du site www.whatafix.com, le site, tout
              son contenu (marque, textes, logos, structure, images,
              animations, photographies, illustrations, charte graphique,
              code informatique, savoir-faire etc.) sont la propriété
              exclusive de S.A.S WHAT A FIX. Ils sont protégés par les
              lois françaises et les textes internationaux relatifs en
              terme de propriété intellectuelle et du respect des droits
              d’auteur et du copyright. S.A.S WHAT A FIX n’accorde aucun
              droit d’utilisation, aucune licence,sur son site, sa/ses
              marque(s) sans son consentement préalable. Toute
              reproduction, représentation, diffusion ou rediffusion,
              totale ou partielle du Site et des bases de données figurant
              sur le Site et/ou sur tout support que ce soit et/ou par
              quelque moyen que ce soit, sans l’autorisation expresse et
              écrite de la Société S.A.S WHAT A FIX est interdite et
              constituerait une contrefaçon susceptible d’engager la
              responsabilité civile et pénale de son auteur.
            </p></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
